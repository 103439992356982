/* Banners list styles
------------------------*/

#banners-list{
    list-style-type: none;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    li{
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
    }
}