/* Print styles
-------------------------------------*/

body {
	font-size: .9em;
	font-family: $font-family-print;
	background-color: white;
	color: black;
}

h1, h2, h3, h4 {
	font-family: $font-family-print;
}

h1 {
	font-size: 1.6em;
}

h2 {
	font-size: 1.4em;
}

h3 {
	font-size: 1.2em;
}

h4 {
	font-size: 1.1em;
}

a img {
	border: none;
}

article a {
	&:link:after, &:visited:after {
		content: " (" attr(href) ") ";
		font-size: 90%;
	}
}