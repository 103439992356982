/* Forms
-------------------------------------*/

//error wrapper styles
div.form-errors-wrapper{
	list-style-type: none;
	font-size: $font-size-content;
	.server-error-wrapper{
		position:relative;
		padding-right: 40px;
		.btn-close-server-err{
			position: absolute;
			display:inline-block;
			top:50%;
			right:0px;
			transform: translate(-100%,-50%);
			cursor:pointer;
			font-size:1.0rem;
		}
	}
}
//form default styles
form {
	
	input[type=checkbox], input[type=radio]{
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	fieldset{
		margin-top: 20px;

		&.subs-rubric{ //first checkbox after title has no margin top
			div.checkbox{
				&:first-of-type{
					margin-top: 0px;
				}
			}
		}
	}

	//error list
	ul.form-error-list {
		position: relative;
		padding: 10px;
		border-radius: $border-radius;
		padding-right: 50px; // prevent icon colapsing with text
		.close-btn {
			position: absolute;
			display: inline-block;
			top: 50%;
			right: 0px;
			transform: translate(-100%, -50%);
			cursor: pointer;
			font-size: $font-size-content;
		}
		li {
			&:before {
				font-family: FontAwesome;
				content: "\f12a";
				display: inline-block;
				padding: 5px 10px;
			}
		}
	}

	//font-size: 1.4rem;
	.form-group{

		//text area prevent horizontal resize
		textarea{
			resize: vertical;
		}
		//form description area text styles
		p{
			&.label-text{
				font-size: 1rem !important;
			}
		}
		//date selector with custom date selector
		.date-wrapper{
			position: relative;
			a{
				//position date link inside input
				position:absolute;
				right: 0px;
				top: 50%;
				transform: translate(-200%,-50%);
				transition: all 150ms ease-in;

				//custom styles for icon
				&.fa-calendar{
      				color: $gray-200 !important;
				}
				&:hover{
					color: $primary !important;
					transform: translate(-200%,-50%) scale(1.01);
				}
				&:active{
					color: lighten($primary,10%) !important;
					transform: translate(-200%,-50%) scale(0.98);
				}
			}
		}
	}
}

//after note at gids eml subscription form
.after-note{
	margin-top: 20px;
}