/* ---- header section ---- */

header {
    position: relative; 
   
    .header-wrapper {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
    }
    
    /* Search
    -------------------------------------*/


    
    #search-mini-form {
        position: relative;
        display: flex;

        input.input-submit {
            margin-left: 4px;

        }
        #autocomplete-wrapper{
            position: absolute;
            top: 100%;
            width: 100%;
        }
    }

    #input-search-mini {
        padding: .3em;
        font-size: 100%;
        width: 132px;
    }

}