@media screen and (max-width: 768px) {
    html{
        font-size: $font-size-html - 1;
    }
    //mobile responsivness
    nav{
        #main-menu{
            margin: 0px;
            li.menu-level1{
                display: block;
                ul.menu-level2{
                    position: relative;
                    width: 100%;
                    transform: none;
                }
            }
        }
    }
    body {
        //form date popup responsivness
        div#dp-popup {
            left: auto !important;
            right: 10px !important;
        }
        header {
            &.navbar-margin-bottom {
                margin-bottom: 90px;
            }
            //remove extra margin from search miniform btn
            #search-mini-form {
                input {
                    &.input-submit {
                        margin-left: 0px;
                    }
                }
            }
        }
        nav {
            &.navbar {
                &.navbar-top-collapse {
                    border:none;
                    position: fixed;
                    left: 0px;
                    right: 0px;
                    background-color: white;
                    z-index: 1;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    max-height: 100vh;
                    overflow: auto;
                    .navbar-top-collapse-header {
                        display: table;
                        .logo-wrapper, .btn-wrapper {
                            vertical-align: middle;
                        }
                        .logo-wrapper {
                            display: table-cell;
                            width: 100%;
                        }
                        .btn-wrapper {
                            display: table-cell;
                            text-align: center;
                        }
                    }
                    .navbar-collapse {
                        border: none;
                    }
                }
                &.navbar-side-collapse {
                    min-height: 0px;
                    border: none;
                }
                .side-navbar-img-wrapper {
                    margin: 20px 10px;
                }
               
                #bs-main-menu-collapse-side {
                    
                    top:0px;
                    background: white;
                    z-index: 99999;
                    bottom:0;
                    left:0;
                    width:300px;
                    position:fixed;
                    overflow:hidden;
                    transition:width .4s;
                    overflow-y: auto;
                    &.in {
                        width: 0;
                    }
                    &:not(.in) {
                        padding: 10px 25px;
        
                    }
                }
               
            }
        }
        .side-collapse-container {
            width:100%;
            position:relative;
            left:0;
            transition:left .4s;
            &.out {
                left:300px;
            }
        }
        .table-wrapper {
            .logo-wrapper {
                vertical-align: middle;
                width: 100%;
            }
            .hamburger-wrapper {
               vertical-align: middle;
            }
        }
        
    }
}
