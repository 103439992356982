// Import fonts
@import url('https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700|Open+Sans:300,400,600|Raleway:300,400,600');

// Common font families
$font-family-headers: 'Raleway', sans-serif;
$font-family-content: 'Open Sans', sans-serif;
$font-family-print: 'Old Standard TT', serif;


// Common font sizes
$font-size-html: 14px; //rem default size
$font-size-content: 1rem;
$font-size-content-minus: 0.8rem;
$font-size-content-plus: 1.2rem;

$font-size-heading-1: 3rem;
$font-size-heading-2: 2.4rem;
$font-size-heading-3: 2rem;

$font-size-sidebar-headers: 1.8rem;

//bussiness card
$font-size-bussinesscard: 1rem;

// --- navigation --- //
$font-size-navbar: 1.4rem;