/* --- Content --- */

body#body-content {
    main {
        color: $color-base-text;
        margin-bottom: .7em;
        p {
            margin-bottom: 0;
            padding-bottom: 1em;
            line-height: 1.35;
            font-size: $font-size-content;
        }
        a {
            &:link,
            &:visited,
            &:hover,
            &:active {
                color: $link-color;
                text-decoration: underline;
            }
            &:hover {
                color: $link-color;
            }

        }
        >img.content-photo,
        >p>img.content-photo {
            max-width: 100%;
        }
        ul {
            padding-bottom: 1em;
            padding-left: 0;
            ul {
                padding-top: 1em;
                padding-left: 40px;
            }
            li {
                font-size: $font-size-content;
                line-height: 1.35;
            }
            &:not(.form-error-list) {
                li {
                    margin-bottom: .3em;
                    list-style-type: disc;
                    list-style-position: inside;
                    font-size: $font-size-content;
                }
            }
            &.news-list {
                margin-top: 2em;
                padding-left: 0;
                li {
                    margin-bottom: 1em;
                    list-style-type: none;
                    font-size: $font-size-content;
                    a {
                        &:link,
                        &:visited,
                        &:hover,
                        &:active,
                        &:visited {
                            text-decoration: none;
                        }
                        &:hover {
                            text-decoration: underline;
                            span {
                                text-decoration: underline;
                            }
                        }
                        span.date {
                            font-weight: normal;
                        }
                    }
                }
            }
            &.link-list {
                margin-top: 2em;
                padding-left: 0;
                li {
                    margin-bottom: 1em;
                    list-style-type: none;
                    font-size: $font-size-content;
                }
            }
            &.article {
                float: left;
                width: 100%;
                padding-bottom: 40px;
                li {
                    float: left;
                    list-style-type: none;
                    padding: 0;
                    margin: 0 3px 0px 0px;
                    margin: 0 -5px 0px 0px;
                    a {
                        &:link,
                        &:visited,
                        &:hover,
                        &:active {
                            text-decoration: none;
                            padding: 5px 5px 4px 5px;
                            background: #606060;
                            border: 1px solid #333;
                            color: white;
                        }
                    }
                    &.active a {
                        &:link,
                        &:visited,
                        &:hover,
                        &:active {
                            background: #B90000;
                        }
                    }
                    a:hover {
                        background: #B90000;
                    }
                }
            }
            &.paging {
                margin: 1em 0;
                li {
                    display: inline-block;
                    margin: 0 .3em;
                    list-style-type: none;
                    font-size: $font-size-content;
                    font-weight: 500;
                    a {
                        &:link,
                        &:visited,
                        &:hover,
                        &:active,
                        &:visited {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        ol {
            padding-bottom: 1em;
            padding-left: 0;
            ol {
                padding-top: 1em;
                padding-left: 40px;
            }
            li {
                margin-bottom: .3em;
                list-style-type: decimal;
                list-style-position: inside;
                font-size: $font-size-content;
                & li {
                    font-size: $font-size-content;
                }
            }
            &.form-error {
                margin: 1em 0;
                padding: .3em 0;
                border: 1px solid $danger;
                color: $danger;
                li {
                    padding: .3em 1em;
                    font-weight: bold;
                    line-height: 1.35;
                }
            }
        }
        blockquote {
            font-size: $font-size-content-plus;
            line-height: 1.35;
            font-style: italic;
        }
        /* --- Attachments list --- */
        ul.attachments-list li {
            list-style-type: none;
        }
        /* --- tables --- */
        table {
            margin-bottom: 1em;
            font-size: $font-size-content;
            th {
                padding: .3em .5em;
                border: 1px solid #999;
                font-weight: bold;
                text-align: left;
            }
            td {
                padding: .3em .5em;
                border: 1px solid #999;
            }
            caption {
                margin-bottom: .3em;
                text-align: left;
                font-weight: bold;
            }
        }
        /* --- Icons in content --- */
        a {
            &.lipdf {
                padding: .2em 0 .1em 19px;
                background: transparent url(../images/pdf.gif) 0% 0.2em no-repeat;
            }
            &.lidoc {
                padding: .2em 0 .1em 19px;
                background: transparent url(../images/doc.gif) 0% 0.2em no-repeat;
            }
            &.lixls,
            &.lixlsx {
                padding: .2em 0 .1em 19px;
                background: transparent url(../images/xls.gif) 0% 0.2em no-repeat;
            }
            &.lippt {
                padding: .2em 0 .1em 19px;
                background: transparent url(../images/ppt.gif) 0% 0.2em no-repeat;
            }
            &.lizip {
                padding: .2em 0 .1em 19px;
                background: transparent url(../images/zip.gif) 0% 0.2em no-repeat;
            }
            &.liai {
                padding: .2em 0 .1em 19px;
                background: transparent url(../images/ai.gif) 0% 0.2em no-repeat;
            }
            &.liexe {
                padding: .2em 0 .1em 19px;
                background: transparent url(../images/exe.gif) 0% 0.2em no-repeat;
            }
            &.liimage {
                padding: .2em 0 .1em 19px;
                background: transparent url(../images/file.gif) 0% 0.2em no-repeat;
            }
        }
        /* --- Search results --- */
        #search-main-form {
            margin-bottom: 1em;
        }
        .search-results-row {
            a {
                &:link,
                &:visited,
                &:hover,
                &:active {
                    font-size: $font-size-content;
                    font-weight: bold;
                    color: black;
                }
                &:visited {
                    color: #666;
                }
            }
            .search-results-url {
                display: block;
                width: 100%;
                font-style: italic;
                font-size: $font-size-content-minus;
                word-break: break-all;
                color: #00A900;
            }
        }
        /* --- guestbook --- */
        .guestbook-list {
            dt p {
                padding-bottom: 0;
                &.name {
                    font-weight: bold;
                }
                &.date {
                    font-style: italic;
                }
            }
            dd {
                clear: left;
                padding: 1em 0 1.5em 0;
                background: url(../images/backgrounds/hor-separator.png) no-repeat bottom center;
                margin-bottom: 1.5em;
            }
        }
        /* --- Photogallery --- */
        #photo-gallery-list {
            width: 100%;
            margin: 1em 0;
            .photo-gallery-item {
                position: relative;
                padding: 5px;
                text-align: center;
                .image-container {
                    position: relative; //							border: 1px solid $color-grey-mid;
                    background-color: $gray-100;
                    .one-to-one.aspect-ratio {
                        padding-bottom: 100%
                    }
                    a {
                        &:link,
                        &:visited,
                        &:hover,
                        &:active {
                            display: inline-block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            padding: 5px;
                        }
                    }
                }
                &.portrait img {
                    height: 100%;
                }
                &.landscape img {
                    width: 100%;
                    position: relative;
                    top: 50%;
                    transform: perspective(1px) translateY(-50%);
                }
                .gallery-title {
                    margin-top: .7em;
                    text-align: left;
                    line-height: 1.1;
                    font-size: $font-size-content-plus;
                    color: $gray-700;
                    overflow: hidden;
                    text-align: center;
                    strong {
                        display: block;
                    }
                }
            }
        }
        #photo-gallery-container {
            width: 100%;
            margin: 1em 0;
            .photo-gallery-item {
                position: relative;
                padding: 5px;
                text-align: center;
                .image-container {
                    position: relative; //							border: 1px solid $color-grey-mid;
                    background-color: $gray-100;
                    .one-to-one.aspect-ratio {
                        padding-bottom: 100%
                    }
                    a {
                        &:link,
                        &:visited,
                        &:hover,
                        &:active {
                            display: inline-block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            padding: 5px;
                        }
                    }
                }
                &.portrait img {
                    height: 100%;
                }
                &.landscape img {
                    width: 100%;
                    position: relative;
                    top: 50%;
                    transform: perspective(1px) translateY(-50%);
                }
                .image-title {
                    margin-top: .7em;
                    text-align: left;
                    line-height: 1.1;
                    font-size: $font-size-content-plus;
                    color: $gray-700;
                    overflow: hidden;
                    text-align: center;
                    strong {
                        display: block;
                    }
                }
            }
        }
        /* --- Photo list - obogaten seznam --- */
        .photo-list {
            .photo-list-item {
                margin-bottom: 1em;
                .photo-list-photo {
                    img {
                        max-width: 100%;
                    }
                }
                .photo-list-content {
                    .photo-list-content-header {

                        margin-top: 0px;

                    }
                }
            }
        }
        #photo-list-lead-image {
            float: left;
            margin: 0 10px 3px 0;
        }

        /* --- Cnt list --- seznam */
        .cnt-list {
            .date {}
            .title {
                padding-left: .3em;
            }
            .subtitle {
                display: block;
                width: 100%;
                padding-left: 1em;
                font-size: $font-size-content-minus;
            }
        }
        /* --- Link list --- ie: link to news subscribe / archive*/
        .link-list a {
            &:link,
            &:visited,
            &:hover,
            &:active {
                font-size: $font-size-content;
            }
        }
        article .link-list a {
            &:link,
            &:visited,
            &:hover,
            &:active {
                font-size: $font-size-content;
            }
        } //ko je seznamski prikaz razvščen po datumu
        article ul.cnt-list.with-date li {
            list-style: none !important;
        }
    }
}