/* --- Entry --- */
	
body#body-entry {
	
	.video-wrapper{
		height: 100vh;
		width: 100%;
		display: flex;
		background-color: #000;
		flex-direction: column;
		justify-content: center;
	}
}