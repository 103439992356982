h1 {
    margin-bottom: .5em; //relative to current font size if rem then it would be base*50% always
    font-family: $font-family-headers;
    font-size: $font-size-heading-1;
    line-height: 1;
}
h2 {
    margin-bottom: .5em;
    font-family: $font-family-headers;
    font-size: $font-size-heading-2;
}
h3{
    margin-bottom: .5em;
    font-family: $font-family-headers;
    $font-size: $font-size-heading-3;
}
