/* --- bussiness card --- */

.bc-wrapper{
	text-align: center;
}

.business-card {
	position: relative;
	display: inline-block;
	margin-bottom: 1.5em;
	padding: .5em .5em .5em 20px;
	font-size: $font-size-bussinesscard;
	text-align: left;
}

p.email a {
	&:link, &:visited, &:hover, &:active {}
}

.business-card {
	p.org {
		font-weight: bold;
	}

	.tel, .tel2 {
		margin-left: -15px;
		padding: 3px 0 3px 15px;
		background: url('../images/tel.gif') no-repeat 0 1px;
	}

	.fax {
		margin-left: -15px;
		padding: 3px 0 3px 15px;
		background: url('../images/fax.gif') no-repeat 0 4px;
	}

	p.email {
		margin-left: -17px;
		padding: 3px 0 3px 17px;
		background: url('../images/email.gif') no-repeat 0 6px;
	}
}